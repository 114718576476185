
export class MemberEligibility {
  recordID!: number;
  payor!: string;
  mvdid!: string;
  lob!: string;
  effectiveDate!: string;
  endDate!: string;
  cmOrgRegion!: string;
  plan: string | null = null;
  aidCategory: string | null = null;
  payorName!: string;
  payorID!: string;
  lobName!: string;
  companyName!: string;
  groupName!: string;
  subGroupName!: string;
  uMAliasGroupKey!: number | null;
  uMAliasSubGroupKey!: number | null;
  umContractAlias!: string;
  umContractClasses!: UMContract[];
  contractToolTip!: string;
  
  constructor() { }
}

export class UMContract {
  umAlias!: string;
  uMAliasGroupId!: string;
  uMAliasSubGroupId!: string;
}