import { FilterBase } from "../filter/FilterBase";

export class Task extends FilterBase{
updatedDate!: Date;
outcomeNotes!: string;
outcomeId!: number;
outcomeDesc!: string;
completedBy! :string;
completedDate!: string;
reminderDate!: Date;
dueDate!: string;
createdDate!: string;
narrative!: string;
groupId!: number;
owner!: string;
ownerFullName!: string
createdBy!: string;
title!: string;;
statusId!: number;
statusDesc!: string;
priorityId!: number;
priorityRank!: number;
priorityDescription!: string;
typeId!: number;
taskType!: string;
authIntakeId!: number;
authNumber!: number;
productId!: number;
customerId!: number;
taskId!: number;
mvdid!: string;
memberId!: string;
memberName!: string;
updatedBy!: string;
isDeleted!: boolean;
// hidden = false;
checked = false;
showAsNew: boolean = false;
authDetailNumber!: string;
style!: string;
warningTitle!: string;
recordCount!: number;
}

export class TaskAddInput {
customerId!: number;
productId!: number;
mvdId!: string;
authIntakeId!: number;
typeId!: number
priorityId!: number;
statusId!: number;
user!: string;
owner!: string | null;
groupId!: number | null;
narrative!: string;
dueDate!: Date;
reminderDate!: Date;
completedDate!: Date;
completedBy!: string;
outcomeId!: number | null;
  outcomeNotes!: string;
  authDetailId!: number;
}

export class TaskUpdateInput {
customerId!: number;
productId!: number;
taskId!: number;
authIntakeId!: number;
typeId!: number;
priorityId!: number;
statusId!: number;
user!: string;
owner!: string | null;
groupId!: number | null;
narrative!: string;
dueDate!: Date;
reminderDate!: Date;
completedDate!: Date;
completedBy!: string;
outcomeId!: string;
outcomeNotes!: string;
isDeleted!: boolean;
}
