import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@CommonLib/components/material/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { DialogTaskComponent } from './dialog-task.component';
import { DirectivesModule } from '../../../directive/directives.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    DirectivesModule
  ],
  declarations: [
    DialogTaskComponent
  ],
  exports: [
    DialogTaskComponent
  ]
})
export class DialogTaskModule { }
