export enum queueTypes {
    TEAM_AUTHORIZATION_QUEUE,
    TEAM_TASK_QUEUE,
    MEMBER_AUTHORIZATION_QUEUE,
    MEMBER_TASK_QUEUE
}

export enum ReferTaskTypes {
    MY_ASSIGNMENT,
    WORK_QUEUE,
    TEAM_MEMBER
  }

export enum snapshotFilterTypes {
    ASSIGNED_TO_ME,
    OVERDUE,
    AWAITING_INFO,
    TASKS_DUE_TODAY,
    IN_PHYSICIAN_REVIEW,
    AUTHS_DUE_TODAY
}

export enum AuthFormTabs {
  ELIGIBILITY,
  REQUEST,
  PROVIDER,
  DIAGNOSIS,
  CLINICAL,
  PHYSICIAN,
  NOTIFICATIONS,
  DISPOSITION,
  NOTES,
  ATTACHMENTS
}

export const TASK_SNAPSHOTFILTERS = [snapshotFilterTypes.TASKS_DUE_TODAY];

export const SUPERAUTHS = 'super-auths';
export const SUPERINFAXES = 'super-infaxes';
export const SUPERTASKS = 'super-tasks';
export const SUPEROUTFAXES = 'super-outfaxes';
export const TASKHISTORY = 'task-history';
export const TEAMQUEUEAUTHS = 'team-queue-auths';
export const TEAMQUEUEFAXES = 'team-queue-faxes';
export const TEAMQUEUETASKS = 'team-queue-tasks';
export const MYQUEUEAUTHS = 'my-queue-auths';
export const MYQUEUETASKS = 'my-queue-tasks';
export const CHARTHISTORY = 'chart-history';
export const MYQUEUEFAXES = 'my-queue-faxes';

export enum PROVIDER_TYPE {
  REQUESTING='REQUESTING',
  SERVICING='SERVICING',
  OTHER='OTHER',
  DISPOSITION='DISPOSITION'
}

export const CLIENT_ABCBS = 16;
export const PRODUCT_UM = 5;

export const NOTETYPES = 'note type';
export const NOTETYPE_PHYSICIAN_REVIEW = 10078;
export const NOTETYPE_PHYSICIAN_REVIEWREF = 10079;
export const NOTETYPE_CLINICAL_SUMMARY_INITIAL = 10076;
export const LETTERTYPE = "Letter Type"; 
export const SENDSTATUS = "NotificationSendStatus";
export const RECEPIENTTYPE = "NotificationRecipientType";
export const AUTHORIZATIONTYPE ='authorization type';
export const AUTHORIZATIONURGENCY=109;
export const AUTHTREATMENTTYPE=110;
export const AUTHREQUESTORIGIN=111;
export const AUTHREQUESTTYPE=112;
export const AUTHPLACEOFSERVICE=113;
export const AUTHREQUESTORTYPE=114;
export const AUTHPROVIDERTYPE=115;
export const AUTHORIZATIONSTATUS ='authorization status';
export const INPATIENTADMISSIONTYPE=119;
export const UNITTYPE = 120;
export const ATTACHMENTTYPE = 121;
export const SUPERFILTERTYPE = 146;

export const RXFREQUENCY=123;
export const NOTIFICATIONSENDSTATUS=126;
export const DISCHARGEDISPOSITION=127;
export const AUTHORIZATIONSTATUSREASON ='authorizationstatusreason';
export const REVIEWOUTCOMEREASON ='clinicalreviewoutcomereason';
export const OUTCOMEREASON_PHYSICIAN_REVIEW = 10146;
export const AUTHORIZATIONREVIEWTYPE ='authorizationreviewtype';
export const CLINICAL_REVIEW = 10149;
export const PHYSICIAN_REVIEW = 10150;
export const REVIEWOUTCOME = 'clinical review outcome'; 
export const DENIED_OUTCOMES = [10102, 10103];
export const APPROVED_OUTCOME = 'Approved';
export const WITHDRAWN_OUTCOME = 'Withdrawn';
export const DENIED_OUTCOME = 'denied';
export const PARTIALLYDENIED_OUTCOME = 'partiallydenied';
export const PENDED_OUTCOME = 'pended';
export const VOIDED_OUTCOME = 10099;

export const AUTHSTATUS_PENDING_REVIEW = 'pendingreview';
export const AUTHSTATUSREASON_PHYSICIANREVIEW = 'physicianreview';

export const RXROUTE=131;
export const GUIDELINETYPE = 132;
export const AUTHEDITREASON = 134;


export const TASKTYPE = 102;
export const TASKPRIORITY = 103;
export const TASKPRIORITY_HIGH = 10008;
export const TASKSTATUS = 105;
export const TASKOUTCOME = 133;
export const TASKOUTCOME_REVIEWCOMPLETE = 10177;
export const TASKOUTCOME_LETTERSENT = 10178;
export const TASKOUTCOME_ADDINFORECD = 10179;
export const TASKOUTCOME_PROVIDERNORESP = 10180;
export const TASKOUTCOME_NOADDRESSONFILE = 10181;
export const TASKOUTCOME_REQWITHDRAWN = 10182;
export const TASKTYPE_MDREVIEW = 10003;

export const TASKSTATUS_PENDING = 'Pending';
export const TASKSTATUS_SUCCESSFUL = 'Successful';
export const TASKSTATUS_UNSUCCESSFUL = 'Unsuccessful';

export const TASK_MAX_HRS = 18
export const TASK_MAX_MIN = 0
export const TASK_MIN_HRS = 9

export const LETTERTYPE_MEMBERLETTER = "MemberLetter";
export const LETTERTYPE_PROVIDERFAX = "ProviderFax";
export const NOTIFICATIONCLASS_VERBAL = 1;
export const NOTIFICATIONCLASS_WRITTEN = 2;
export const RECEIPIENTTYPE_PROVIDER = "Provider";
export const RECEIPIENTTYPE_MEMBER = "Member";
export const SENDSTATUSID_PENDING = "Pending";

export const AUTO_CLOSE_IDLE_DIALOG_SECONDS = 30; //30 Seconds before the dialog automatically closes and logs out.

export const ELIGIBILITY_TAB_NAME = 'eligibility';
export const DISPOSITION_TAB_NAME = 'disposition';
export const PROVIDER_TAB_NAME = 'provider';
export const DIAGNOSIS_TAB_NAME = 'diagnosis';
export const REQUEST_TAB_NAME = 'request';
export const DIAGNOSIS_RX_TAB_NAME = 'diagnosisRx';
export const DETERMINATION_SECTION = 'determination';
export const DIAGNOSIS_PX_TAB_NAME = 'diagnosisPx'

export const PAGINATION_SIZES = [5, 10, 20]
export const DEFAULT_PAGE_SIZE = 20

export const NOTE_LOCK_HRS = 24;

export const ACCESS_MASK_READ = 0x01; //1
export const ACCESS_MASK_EDIT = 0x02; //2
export const ACCESS_MASK_CREATE = 0x04;//4
export const ACCESS_MASK_DELETE = 0x08;//8
export const ACCESS_MASK_DOWNLOAD = 0x10;//16
export const ACCESS_MASK_PRINT = 0x20;//32
export const ACCESS_MASK_SAVE = 0x40;//64

export const APPCLASS_NOTE = 1;
export const MDREVIEW_GRP = 7;

export const COMPLETE_REVIEW_TASKTEMPLATE = 'CompleteMDReview';
export const COMPLETED_REVIEW_TASKTEMPLATE = 'MDReviewCompleted';

export const DATE_WITH_SECONDS_AMPM = 'MM/dd/yyyy h:mm:ss a';
export const DATE_WITH_SECONDS = 'MM/dd/yyyy h:mm:ss';
export const DATE_WITH_MINUTES = 'MM/dd/yyyy hh:mm';
export const DATE_MDY = 'MM/dd/yyyy';
export const ISO_FORMAT = 'yyyy-MM-ddTHH:mm:ss.SSS';

export enum alertOptions {
  ACK='ACK',
  DEL='DEL',
  NEW='NEW'
}

export const DOCTYPE_ATTACHMENT = 'um_attachment';
export const DOCTYPE_ADDRESSDOC = 'um_addressdoc';
export const DOCTYPE_AVATAR = 'avatar';
export const TOKEN_KEY = 'auth-token';
export const USER_KEY = 'auth-user';
export const MANAGERLIST_KEY = 'supervisor-managers';
export const FILTERS_KEY = 'session-filters';
export const USER_CONFIG = 'user_config';
export const USER_RBAC = 'user_rbac';