import { AuthIntakeDetail } from "./authIntakeDetail";
import { AuthIntakeHeader } from "./authIntakeHeader";
import { AuthIntakeDisposition } from "./authIntakeDisposition";
import { AuthIntakeDispositionDetail } from "./authIntakeDispositionDetail";
import { AuthIntakeDx } from "./authIntakeDx";
import { AuthIntakeOtherProvider } from "./authIntakeOtherProvider";
import { AuthIntakeNote } from "./authIntakeNote";
import { AuthIntakeNotification } from "./authIntakeNotification";
import { AuthIntakeNotificationDetail } from "./authIntakeNotificationDetail";
import { AuthIntakeRfi } from "./authIntakeRfi";
import { AuthIntakeProcCodes } from "./authIntakeProcCodes";
import { AuthIntakeReview } from "./authIntakeReview";
import { AuthIntakeReviewDetail } from "./authIntakeReviewDetail";
import { AuthIntakeStatus } from "./authIntakeStatus";
import { AuthIntakeEditHistory } from "./authIntakeEditHistory";
import { AuthIntakeUserDefinedValues } from "./authIntakeUserDefinedValues";
import { AuthIntakeAttachment } from "./authIntakeAttachment";
import { AuthIntakeInterqualReview } from "../interqual/authIntakeInterqualReview";
import { TemporaryProviderAddress } from "../authView/TemporaryProviderAddress";

export class AuthIntakeForm {
  authIntakeHeader: AuthIntakeHeader;
  authIntakeDetail: AuthIntakeDetail[];
  authIntakeDisposition: AuthIntakeDisposition[];
  authIntakeDispositionDetail: AuthIntakeDispositionDetail[];
  authIntakeDxes: AuthIntakeDx[];
  authIntakeOtherProviders: AuthIntakeOtherProvider[];
  authIntakeNotes: AuthIntakeNote[];
  authIntakeNotifications: AuthIntakeNotification[];
  authIntakeNotificationDetail: AuthIntakeNotificationDetail[];
  authIntakeRfi: AuthIntakeRfi[];
  authIntakeAttachments: AuthIntakeAttachment[];
  authIntakeProcCodes: AuthIntakeProcCodes[];
  authIntakeReview: AuthIntakeReview[];
  authIntakeReviewDetail: AuthIntakeReviewDetail[];
  authIntakeStatus: AuthIntakeStatus[];
  draftKey!: string;
  userName!: string;
  saveDate!: string;
  authIntakeEditHistory: AuthIntakeEditHistory | null;
  authIntakeUserDefinedValues: AuthIntakeUserDefinedValues[] | null;
  authIntakeInterqualReview: AuthIntakeInterqualReview[] | null;
  temporaryProviderAddress: TemporaryProviderAddress[] | null;
  
  constructor() {
    this.authIntakeHeader = new AuthIntakeHeader();
    this.authIntakeDetail = [new AuthIntakeDetail()];
    this.authIntakeAttachments = [];
    this.authIntakeDisposition = [];
    this.authIntakeDispositionDetail = [];
    this.authIntakeNotes = [];
    this.authIntakeNotificationDetail = [];
    this.authIntakeNotifications = [];
    this.authIntakeDxes = [];
    this.authIntakeProcCodes = [];
    this.authIntakeOtherProviders = [];
    this.authIntakeReview = [];
    this.authIntakeReviewDetail = [];
    this.authIntakeRfi = [];
    this.authIntakeStatus = [];
    this.authIntakeEditHistory = null;
    this.authIntakeUserDefinedValues = [];
    this.authIntakeInterqualReview = [];
    this.temporaryProviderAddress = [];
  }
}
