<div class="queue-utilities" data-layout="row" data-layout-align="space-between center">
  <mat-checkbox title="Select or Deselect All" aria-label="Select or Deselect All" [(ngModel)]="allSelected" (click)="selectOrDeselectAll()"></mat-checkbox>
  <!--Refer button becomes visible when any of the checkboxes are selected. Otherwise hidden.-->
  <button mat-flat-button color="vdt-primary" (click)="dialogTask.openReferTaskDialog()" [disabled]="selectedTasks.length == 0">Refer: {{selectedTasks.length}}</button>
  <div data-layout="row" data-layout-align="space-between center">
    <span class="count">Showing: {{totalTasks}}</span>
    <app-refresh (iconClick)="refreshQueue()" [title]="refreshTitleText"></app-refresh>
  </div>
  <button mat-icon-button title="Filter queue" (click)="openFilterDialog()" matTooltipClass="multiline-tooltip" matTooltip="{{filterBadgeData.text}}">
    <mat-icon matBadge={{filterBadgeData.count}}
              matBadgeColor="warn"
              matBadgeSize="small"
              [matBadgeHidden]="filterBadgeData.count==0"
              class="material-icons-outlined icon-dark"
              aria-hidden="false"
              aria-label="Filter queue">
      filter_list
    </mat-icon>
  </button>
  <span class="cdk-visually-hidden">
    Notification icon with overlaid badge showing the number 0
  </span>
</div>
<cdk-virtual-scroll-viewport name="teamTasks" itemSize="1" autosize style="height: calc(100vh - 257px)">
  <div *cdkVirtualFor="let task of allTasksList; templateCacheSize: 0">
    <div *ngIf="!task.hidden">
      <app-queue-task [task]="task"
                      [isSelectable]="true"
                      (taskAction)="taskSelectedToggle($event)">
      </app-queue-task>
    </div>
  </div>
</cdk-virtual-scroll-viewport>
<app-dialog-task #dialogTask
                 (referDialogEvent)="referDialogEvent($event)">
</app-dialog-task>

<app-task-filter [openDialog]="openDialogEvent"
                 [tasks]="teamTasks"
                 (dialogEvent)="dialogEvent($event)"
                 (displayCountEvent)="displayCountEvent($event)"
                 [badgeData]="filterBadgeData"
                 [filterType]="filterType">
</app-task-filter>
