import { Component, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DialogComponent, IDialogConfig } from '@CommonLib/components/dialog/dialog.component';
import { EventEmitter } from '@angular/core';
import { Task, TaskAddInput, TaskUpdateInput } from "../../../../models/task/task";
import * as moment from 'moment';
import { User }  from "../../../../models/user";
import { DATE_WITH_SECONDS_AMPM, ReferTaskTypes, TASKOUTCOME, TASKOUTCOME_ADDINFORECD, TASKOUTCOME_LETTERSENT, TASKOUTCOME_NOADDRESSONFILE, TASKOUTCOME_PROVIDERNORESP, TASKOUTCOME_REQWITHDRAWN, TASKOUTCOME_REVIEWCOMPLETE, TASKPRIORITY, TASKSTATUS, TASKSTATUS_SUCCESSFUL, TASKSTATUS_UNSUCCESSFUL, TASKTYPE, TASK_MAX_HRS, TASK_MAX_MIN, TASK_MIN_HRS } from "../../../../../assets/constants";
import { Queue } from '../../../../models/configuration/queue';
import { MatDialog } from '@angular/material/dialog';
import { Auth } from 'projects/um/src/app/member/authorizations/auth-landing/auth-landing.component';
import { LookupValue } from 'projects/um/src/app/models/Lookups/lookupValue';
import { AuthIntakeForm } from 'projects/um/src/app/models/authForm/authIntakeForm';
import { ConfigService } from 'projects/um/src/app/services/config-service.service';
import { LookupService } from 'projects/um/src/app/services/lookup.service';
import { TaskService } from 'projects/um/src/app/services/task-service.service';
import { TokenStorageService } from 'projects/um/src/app/services/token-storage.service';
import { AppSettings } from '../../../appsettings';
import { DialogService } from '../../../guards/dialog.service';
import { NotificationService } from '../../../notification.service';

@Component({
  selector: 'app-dialog-task',
  templateUrl: './dialog-task.component.html',
  styleUrls: ['./dialog-task.component.scss']
})
export class DialogTaskComponent implements OnInit, OnDestroy {
  @ViewChild('referTaskDialogTemplate') referTaskTemplate: TemplateRef<any> | undefined;
  @ViewChild('addTaskDialogTemplate') addTaskTemplate: TemplateRef<any> | undefined;
  @ViewChild('updateTaskDialogTemplate') updateTaskTemplate: TemplateRef<any> | undefined;
  @ViewChild('viewTaskDialogTemplate') viewTaskTemplate: TemplateRef<any> | undefined;

  @Output() referDialogEvent: EventEmitter<any> = new EventEmitter<any>();

  teamMemberSearchCtrl = new UntypedFormControl(null, Validators.required);

  referGroup = new UntypedFormGroup({
    workQueueCtrl: new UntypedFormControl({ value: null, disabled: true }, Validators.required),
  })

  get workQueueCtrl() {
    return this.referGroup.controls['workQueueCtrl'];
  }

  filteredOptions!: Observable<User[]>;
  task!: Task;

  taskStatusValues!: LookupValue[];
  taskTypeValues!: LookupValue[];
  taskPriorityValues!: LookupValue[];
  taskOutcomeValues!: LookupValue[];
  taskOutcomeValuesDisplayed!: LookupValue[];
  auth!: Auth;
  userName!: string;
  referSelectedUserName!: string;
  ownerUserFirstLast!: string;
  createdUserFirstLast!: string;
  completedUserFirstLast!: string;
  successId!: number[];
  unsuccessfulId!: number[];
  displayAuthNumber!: string;
  DateFormat = DATE_WITH_SECONDS_AMPM;

  selectedTeamMember(event:any) {
    this.referSelectedUserName = event.option.value.userName;
  }

  taskGroup = new UntypedFormGroup ({
    taskType: new UntypedFormControl(null,Validators.required),
    taskNarrative: new UntypedFormControl(null,Validators.required),
    taskDueDate: new UntypedFormControl(null,Validators.required),
    taskReminderDate: new UntypedFormControl(),
    taskStatus: new UntypedFormControl(null,Validators.required),
    taskPriority: new UntypedFormControl(null,Validators.required),
    taskOutcome: new UntypedFormControl({value:null, disabled: true}, Validators.required),
    taskOutcomeDescription: new UntypedFormControl({value:null, disabled: true}, Validators.required),
    taskCompletedDate: new UntypedFormControl(),
  });
  authNumber: any;
  authForm!: AuthIntakeForm;
  
  get taskType() {
    return this.taskGroup.controls['taskType'];
  }  
  get taskNarrative() {
    return this.taskGroup.controls['taskNarrative'];
  }  
  get taskDueDate() {
    return this.taskGroup.controls['taskDueDate'];
  }  
  get taskReminderDate() {
    return this.taskGroup.controls['taskReminderDate'];
  }  
  get taskStatus() {
    return this.taskGroup.controls['taskStatus'];
  }  
  get taskPriority() {
    return this.taskGroup.controls['taskPriority'];
  }  
  get taskOutcome() {
    return this.taskGroup.controls['taskOutcome'];
  }  
  get taskOutcomeDescription() {
    return this.taskGroup.controls['taskOutcomeDescription'];
  }  
  get taskCompletedDate() {
    return this.taskGroup.controls['taskCompletedDate'];
  }

  constructor(private dialog: MatDialog, private lookupService: LookupService, private dialogService: DialogService, private tokenService: TokenStorageService, 
    private taskService: TaskService, private configService: ConfigService, private appConfig: AppSettings) {
  }

  //Refer Task Dialog
  openReferTaskDialog() {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '35em',
      data: <IDialogConfig>{
        title: 'Refer Task',
        dialogContent: this.referTaskTemplate,
        acceptButtonTitle1: 'Refer',
        declineButtonTitle: 'Cancel'
      }
    });

    
    dialogRef.componentInstance.clickDeclineButton.subscribe(e => {
      dialogRef.close();
    });    
    
    dialogRef.componentInstance.clickAcceptButton1.subscribe(e => {
      if (this.validateReferDialog()) {
        this.referDialogEvent.emit({ referType: this.referType, workQueueTypeId: this.workQueueTypeId, teamMemberId: this.referSelectedUserName });
        dialogRef.close();
      }
    });
  }


  //Create New Task Dialog
  openAddTaskDialog(authIntakeId: number, authDetailId: number, mvdId: string, displayAuthNumber: string) {
    this.displayAuthNumber = displayAuthNumber;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '75em',
      data: <IDialogConfig>{
        title: 'Create a Task',
        dialogContent: this.addTaskTemplate,
        acceptButtonTitle1: 'Assign/Post',
        declineButtonTitle: 'Cancel'
      }
    });
  
    dialogRef.componentInstance.clickDeclineButton.subscribe(() => {
      this.taskGroup.reset();
      dialogRef.close();
    });

    dialogRef.componentInstance.clickAcceptButton1.subscribe(async () => {
      if (this.taskGroup.valid && await this.checkConfirmDates() && this.validateReferDialog()) {
      //save data
        const newTask:TaskAddInput = {
          customerId: this.appConfig.customerId,
          productId: this.appConfig.productId,
          mvdId: mvdId,//this.authForm.authIntakeHeader.mvdId,
          authIntakeId: authIntakeId,//this.authForm.authIntakeHeader.authIntakeId,
          typeId: Number(this.taskType.value),
          priorityId: Number(this.taskPriority.value),
          statusId: Number(this.taskStatus.value),
          user: this.userName,
          owner: null,
          groupId: null,
          narrative: this.taskNarrative.value,
          dueDate: this.taskDueDate.value,
          reminderDate: this.taskReminderDate.value,
          completedDate: this.taskCompletedDate.value,
          completedBy: '',
          outcomeId: this.taskOutcome.value,
          outcomeNotes: this.taskOutcomeDescription.value,
          authDetailId: authDetailId//this.authForm.authIntakeDetail[0].authDetailId
        };

        if (this.successId.includes(newTask.statusId) || this.unsuccessfulId.includes(newTask.statusId)) {
          newTask.completedBy = this.userName;
          newTask.completedDate = new Date();
        }
        if (this.referType == undefined) {
          this.referType = ReferTaskTypes.MY_ASSIGNMENT;
        }
        let ownerAndGroup = this.taskService.setOwnerAndGroup(this.referType, this.referSelectedUserName, this.workQueueTypeId);
        if (ownerAndGroup){
          newTask.owner = ownerAndGroup.owner;
          newTask.groupId = ownerAndGroup.groupId;
        }

        this.taskService.addTask(newTask);
        this.taskGroup.reset();
        this.referGroup.reset();
        this.teamMemberSearchCtrl.reset();
        this.myAssignmentsSelected = true;
        this.workQueueSelected = false;
        this.referGroup.controls['workQueueCtrl'].disable();
        this.teamMemberSelected = false;
        dialogRef.close();
      }
      else {
        this.taskGroup.markAllAsTouched();
      }
    });
  }

  //Update or Complete Task Dialog
  openUpdateTaskDialog(task: Task) {
    this.task = task;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '75em',
      data: <IDialogConfig>{
        title: 'Update/Complete Task',
        dialogContent: this.updateTaskTemplate,
        acceptButtonTitle1: 'Save',
        declineButtonTitle: 'Close'
      }
    });

    this.taskDueDate.setValue(task.dueDate);
    this.taskType.setValue(task.typeId);
    this.taskType.disable();
    this.taskNarrative.setValue(task.narrative);
    this.taskNarrative.disable();
    this.taskReminderDate.setValue(task.reminderDate);
    this.taskStatus.setValue(task.statusId);
    this.taskPriority.setValue(task.priorityId);
    this.taskPriority.disable();
    this.setUserInfo(task, this.userListData);
    this.referSelectedUserName = task.owner;
    this.referGroup.controls['workQueueCtrl'].setValue(task.groupId);

    if (task.owner?.toLowerCase() === this.currentUserName?.toLowerCase()) {
      this.myAssignmentsSelected = true;
      this.workQueueSelected = false;
      this.referGroup.controls['workQueueCtrl'].disable();
      this.teamMemberSelected = false;
      this.referType = ReferTaskTypes.MY_ASSIGNMENT;
    }
    else if (task.owner != null) {
      this.myAssignmentsSelected = false;
      this.workQueueSelected = false;
      this.referGroup.controls['workQueueCtrl'].disable();
      this.teamMemberSelected = true;
      this.referType = ReferTaskTypes.TEAM_MEMBER;
    }
    else if (task.groupId != null) {
      this.myAssignmentsSelected = false;
      this.workQueueSelected = true;
      this.referGroup.controls['workQueueCtrl'].enable();
      this.teamMemberSelected = false;
      this.referType = ReferTaskTypes.WORK_QUEUE;
    }

    dialogRef.componentInstance.clickDeclineButton.subscribe(() => {
      this.taskGroup.reset();
      dialogRef.close();
    });

    dialogRef.componentInstance.clickAcceptButton1.subscribe(async () => {
      if (this.taskGroup.valid && await this.checkConfirmDates() && this.validateReferDialog()) {
          let taskToUpdate: TaskUpdateInput = {
          productId: this.appConfig.productId,
          customerId: this.appConfig.customerId,
          taskId: task.taskId,
          authIntakeId: task.authIntakeId,
          typeId: this.taskType.value,
          priorityId: this.taskPriority.value,
          statusId: this.taskStatus.value,
          user: task.createdBy,
          owner: null,
          groupId: null,
          narrative: this.taskNarrative.value,
          dueDate: this.taskDueDate.value,
          reminderDate: this.taskReminderDate.value,
          completedDate: this.taskCompletedDate.value,
          completedBy: task.completedBy,
          outcomeId: this.taskOutcome.value,
          outcomeNotes: this.taskOutcomeDescription.value,
          isDeleted: false
        };
        if (this.successId.includes(taskToUpdate.statusId) || this.unsuccessfulId.includes(taskToUpdate.statusId)) {
          taskToUpdate.completedBy = this.userName;
          taskToUpdate.completedDate = new Date();
        }
        if (this.referType == undefined) {
          this.referType = ReferTaskTypes.MY_ASSIGNMENT;
        }
        let ownerAndGroup = this.taskService.setOwnerAndGroup(this.referType, this.referSelectedUserName, this.workQueueTypeId);
        if (ownerAndGroup){
          taskToUpdate.owner = ownerAndGroup.owner;
          taskToUpdate.groupId = ownerAndGroup.groupId;
        }
        this.taskService.updateTask(taskToUpdate, task.mvdid);
        dialogRef.close();
      }
      else {
        this.taskGroup.markAllAsTouched();
      }
    });

  }

  //View Completed Task Dialog
  openViewTaskDialog(task:Task) {
    this.setUserInfo(task, this.userListData);
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '40em',
      data: <IDialogConfig>{
        title: 'View Completed Task',
        dialogContent: this.viewTaskTemplate,
        declineButtonTitle: 'Close'
      },
      autoFocus: false
    });
  }

  //Check if Task Status is 'Completed", then open View or Update Task Dialog
  openTaskDialog(task: Task) {
    this.task = task;
    this.taskService.markTaskAsViewed(task.taskId);
    task.showAsNew = false;
    if (this.isCompleted(task.statusId)) {
      this.openViewTaskDialog(task);
    }
    else {
      this.openUpdateTaskDialog(task);
    }
  }

  //toggle active state for the sections
  //not really an elegant way.. room for improvement
  referType: number = ReferTaskTypes.MY_ASSIGNMENT;
  workQueueTypeId!: number;
  userListData!: User[];

  sectionId: string = '';
  myAssignmentsSelected: boolean = true;
  workQueueSelected: boolean = false;
  teamMemberSelected: boolean = false;

  toggleActiveState(event: any) {
    this.myAssignmentsSelected = false;
    this.workQueueSelected = false;
    this.referGroup.controls['workQueueCtrl'].disable();
    this.teamMemberSelected = false;
    this.sectionId = event.target.closest('section').id;
    if (this.sectionId == 'myAssignments') {
      this.referType = ReferTaskTypes.MY_ASSIGNMENT;
      this.myAssignmentsSelected = true;
      this.teamMemberSearchCtrl.reset();
      this.workQueueCtrl.reset();
    }
    if (this.sectionId == 'workQueue') {
      this.referType = ReferTaskTypes.WORK_QUEUE;
      this.workQueueSelected = true;
      this.referGroup.controls['workQueueCtrl'].enable();
      this.teamMemberSearchCtrl.reset();
    }
    if (this.sectionId == 'teamMember') {
      this.referType = ReferTaskTypes.TEAM_MEMBER;
      this.teamMemberSelected = true;
      this.workQueueCtrl.reset();
    }
  };

  OnQueueSelectionChange(event: any) {
    this.workQueueTypeId = event.value;
  }
  
  lookupSub1!: Subscription;
  lookupSub2!: Subscription;
  lookupSub3!: Subscription;
  lookupSub4!: Subscription;
  lookupSub5!: Subscription;
  userSub!: Subscription;
  groupSub!: Subscription;

  currentUserName!: string;
  workQueueTypes!: Queue[];

  ngOnInit(): void {
    this.currentUserName = this.tokenService.getUser().profile.preferred_username;

    this.lookupSub1 = this.lookupService.LookupData.subscribe(data => {
      this.taskTypeValues = data.filter(t => t.lookupSetID == TASKTYPE);
      this.taskPriorityValues = data.filter(t => t.lookupSetID == TASKPRIORITY);
      this.taskOutcomeValues = data.filter(t => t.lookupSetID == TASKOUTCOME);
      this.taskStatusValues = data.filter(t => t.lookupSetID == TASKSTATUS);
      this.successId = data.filter(t => t.lookupStandardValue.toLowerCase() === TASKSTATUS_SUCCESSFUL.toLowerCase()).map(o => o.lookupValueID);
      this.unsuccessfulId = data.filter(t => t.lookupStandardValue.toLowerCase() === TASKSTATUS_UNSUCCESSFUL.toLowerCase()).map(o => o.lookupValueID);
    });

    this.userName = this.tokenService.getUser().profile.preferred_username;
    this.userSub = this.configService.userList.subscribe(data => {
      this.userListData = data;
      this.filteredOptions = this.teamMemberSearchCtrl.valueChanges.pipe(
        startWith(''),
        map(value => {
          if (value != undefined) {
            if (typeof value === 'string' ) return  value; 
            else return value.userName;
          }}),
        map(userName => userName ? this._filter(userName) : this.userListData.slice())
      );
    });
    this.groupSub = this.configService.groupList.subscribe(data => {
      if (data.length > 0) {
        this.workQueueTypes = data;
      }
    });
  }

  ngOnDestroy(): void {
    this.lookupSub1?.unsubscribe();
    this.groupSub?.unsubscribe();
    this.userSub?.unsubscribe();
  }

  displayFn(user: User): string {
    return user && (user.firstName || user.lastName) ? user.firstName+' '+user.lastName: '';
  }

  private _filter(name: string): User[] {
    const filterValue = name.toLowerCase();

    return this.userListData.filter(option => {
      return option.firstName.toLowerCase().includes(filterValue) ||
              option.lastName.toLowerCase().includes(filterValue);
    });
  }

  onStatusChange(event: any) {
    if (this.isCompleted(event.value)) {
      this.taskOutcome.enable();
      this.taskOutcome.reset();
      this.taskOutcomeDescription.enable();
      this.taskOutcomeDescription.reset();

      if (this.successId.includes(event.value)) {
        this.taskOutcomeValuesDisplayed = this.taskOutcomeValues.filter( v => [TASKOUTCOME_REVIEWCOMPLETE, TASKOUTCOME_LETTERSENT, TASKOUTCOME_ADDINFORECD].indexOf(v.lookupValueID) !== -1);
      }
      else {
        this.taskOutcomeValuesDisplayed = this.taskOutcomeValues.filter( v => [TASKOUTCOME_PROVIDERNORESP, TASKOUTCOME_NOADDRESSONFILE, TASKOUTCOME_REQWITHDRAWN].indexOf(v.lookupValueID) !== -1);
      }
    }
    else {
      this.taskOutcomeValuesDisplayed =[];
      this.taskOutcome.disable();
      this.taskOutcomeDescription.disable();
    }

  }

  async checkConfirmDates() {
    if (this.checkBusinessHrs(this.taskDueDate.value) || 
            (this.checkBusinessHrs(this.taskReminderDate.value))) {
              const confirm = await this.dialogService.confirmAsync("You entered Due Date (Reminder Date) which is outside of normal business hours. Do you wish to continue?");
              return confirm;
            }
    return true;
  }

  checkBusinessHrs(datetime: any) {
    if (datetime == null) {
      return false;
    }
    const hour = moment(datetime).hour();
    const min = moment(datetime).minute();
    return (hour == TASK_MAX_HRS && min > TASK_MAX_MIN) || hour > TASK_MAX_HRS || hour < TASK_MIN_HRS;
  }

  isCompleted(value: number): boolean {
    return this.successId.includes(value) || this.unsuccessfulId.includes(value);
  }

  setUserInfo(task: Task, userData: User[]): void {
    const ownerData = this.configService.composeUserInfo(task.owner, userData);
    this.ownerUserFirstLast = ownerData.fullName;
    this.teamMemberSearchCtrl.setValue(ownerData.user);
    this.createdUserFirstLast = this.configService.composeUserInfo(task.createdBy, userData).fullName;
    this.completedUserFirstLast = this.configService.composeUserInfo(task.completedBy, userData).fullName;
  }

  private validateReferDialog():boolean {
    let isClose = true;
    switch (this.referType) {
      case ReferTaskTypes.TEAM_MEMBER:
        if (!this.teamMemberSearchCtrl.valid) {
          this.teamMemberSearchCtrl.markAllAsTouched();
          isClose = false;
        }
      break;
      case ReferTaskTypes.WORK_QUEUE:
        if (!this.referGroup.valid || this.workQueueCtrl.value === 0) {
          this.workQueueCtrl.setValue(null);
          this.referGroup.markAllAsTouched();
          isClose = false;
        }
      break;
    }
    return isClose;
  }
}


