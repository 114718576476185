import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable, map } from "rxjs";
import { Injectable } from "@angular/core";
import { ApiResponse } from "../models/api/apiResponse";
import { AppSettings } from "../shared/appsettings";
import { MemberDemographic } from "@CommonLib/models/member/memberDemographic";
import { TokenStorageService } from "./token-storage.service";
import { MemberAddress } from "../models/member/memberAddress";
import { MemberSearch } from "../models/member/memberSearch";
import { MemberEligibility } from "../models/member/memberEligibility";
import { MemberAltAddress } from "@CommonLib/models/member/memberAltAddress";

@Injectable({
  providedIn: 'root'
})
export class MemberService {
  private memberServiceBaseUrl!: string;
  public mvdId = new BehaviorSubject<string>('');
  private memberEligList = new BehaviorSubject<MemberEligibility[]>([]);
  EligibilityList = this.memberEligList.asObservable();
  private memberDemographics = new BehaviorSubject<MemberDemographic>(new MemberDemographic());
  MemberDemographics = this.memberDemographics.asObservable();
  public MemberAddresses = new BehaviorSubject<MemberAddress[]>([]);
  public MemberSearchResult = new BehaviorSubject<MemberSearch[]>([]);
  user: any;
  memberAltAddress = new BehaviorSubject<MemberAltAddress[]>([]);

  constructor(private http: HttpClient, private config: AppSettings, private tokenService: TokenStorageService) {
    this.memberServiceBaseUrl = config.memberServiceBaseUrl;
    this.user = this.tokenService.getUser();
  }
  /**
   * @brief Reset the demographics BehaviorSubject so we do not keep getting old data when a new member is opened.
   */
  resetDemographics() {
    this.memberDemographics.next(new MemberDemographic());
  }
  /**
   * @brief Reset the EligibilityList BehaviorSubject so we do not keep getting old data when a new member is opened.
   */
  resetEligibility() {
    this.memberEligList.next([new MemberEligibility()]);
  }
  /**
   * @brief Set a new MVDID as BehaviorSubject so all subscribers are updated.
   * @param mvdid
   */
  setMvdid(mvdid: string): void {
    //this.mvdidList.next([mvdid]);
    this.mvdId.next(mvdid);
  }

  getMemberEligibility(mvdId: string, authIntakeId: number | null): Observable<MemberEligibility[] | undefined> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("mvdId", mvdId);
    queryParams = queryParams.append("customerId", this.config.customerId);
    queryParams = queryParams.append("productId", this.config.productId);
    queryParams = queryParams.append("authIntakeId", authIntakeId??'');
    let memberListUrl = this.memberServiceBaseUrl + "MemberEligibility/GetMemberEligibility";

    return this.http.get<ApiResponse<MemberEligibility[]>>(memberListUrl, { params: queryParams }).pipe(
      map((response: ApiResponse<MemberEligibility[]>)=>{
        if (response.succeeded){
          this.memberEligList.next(response.data!);
          return response.data;
        } else {
          return undefined;
        }
      })
    );
  }
  
  getMemberDemographics(mvdId: string, authDetailId: number | null) : Observable<MemberDemographic | undefined> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("mvdId", mvdId);
    queryParams = queryParams.append("customerId", this.config.customerId);
    queryParams = queryParams.append("productId", this.config.productId);
    queryParams = queryParams.append("userName", this.user.profile.preferred_username);
    queryParams = queryParams.append("authDetailID", authDetailId??'');
    let memberListUrl = this.memberServiceBaseUrl + "MemberDemographics/GetMemberData";

    return this.http.get<ApiResponse<MemberDemographic>>(memberListUrl, { params: queryParams }).pipe(
      map((response: ApiResponse<MemberDemographic>)=>{
        if (response.succeeded){
          console.log("MemberDemographics-GetMemberData");
          this.memberDemographics.next(response.data!);
          return response.data;
        } else {
          return undefined;
        }
      })
    );
  }

  // getMemberAddresses(memberId: string) {
  //   let queryParams = new HttpParams();
  //   queryParams = queryParams.append("customerId", this.config.customerId);
  //   queryParams = queryParams.append("productId", this.config.productId);
  //   queryParams = queryParams.append("memberId", memberId);
  //   let memberListUrl = this.memberServiceBaseUrl + "MemberDemographics/GetMemberAddresses";
  //   this.http.get<ApiResponse<MemberAddress[]>>(memberListUrl, { params: queryParams }).subscribe(
  //     data => {
  //       console.log("MemberAddress");
  //       this.MemberAddresses.next(data.data!);
  //     }
  //   );
  // }

  searchMembers(firstName: string, lastName: string, dob: string, memberId: string, authNumber: string): void {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("userName", this.user.profile.preferred_name);
    queryParams = queryParams.append("customerId", this.config.customerId);
    queryParams = queryParams.append("productId", this.config.productId);
    queryParams = queryParams.append("firstName", firstName);
    queryParams = queryParams.append("lastName", lastName);
    queryParams = queryParams.append("dob", dob);
    queryParams = queryParams.append("splMemberId", memberId);
    queryParams = queryParams.append("authNumber", authNumber);

    let memberSearchUrl = this.memberServiceBaseUrl + "MemberSearch/MemberSearch";
    this.http.get<ApiResponse<MemberSearch[]>>(memberSearchUrl, { params: queryParams }).subscribe(
      data => {
        if (data.succeeded == true) {
          console.log("MemberSearch");
          this.MemberSearchResult.next(data.data!);
        }
      }
    );
  }

  //ALT ADDRESS METHODS
  /**
   * @brief Method to retrieve member alternate addresses. This is a common method that will be utilized by both CM & UM
   * @param memberId - string from FinalMember
   * @returns MemberAltAddress[] - List of alternate addresses for a member. Source table dbo.CaseSpaceMemberEdit
   */
  getMemberAltAddresses(memberId: string): Observable<MemberAltAddress[] | undefined>{
    let queryParams = new HttpParams();
    queryParams = queryParams.append("customerId", this.config.customerId);
    queryParams = queryParams.append("memberId", memberId);

    let memberListUrl = this.memberServiceBaseUrl + "MemberDemographics/GetMemberAltAddresses";

    return this.http.get<ApiResponse<MemberAltAddress[]>>(memberListUrl, { params: queryParams }).pipe(
      map((response: ApiResponse<MemberAltAddress[]>)=>{
        if (response.succeeded){
          console.log("MemberDemographics-GetMemberAltAddresses");
          this.memberAltAddress.next(response.data!);
          return response.data;
        } else {
          return undefined;
        }
      })
    );
  }
  /**
   * @brief Method to save/update member alternate address. This is a common method that will be utilized by both CM & UM
   * @param MemberAltAddress 
   * @returns Number - RecordNumber from CaseSpaceMemberEdit
   */
  saveMemberAltAddresses(altAddress: MemberAltAddress): Observable<number | undefined>{
    let headers = new HttpHeaders({ 'x-api-version': '1.0' });
    let options = { headers: headers };

    let memberAltAddressUrl = this.memberServiceBaseUrl + "MemberDemographics/SaveMemberAltAddresses";

    return this.http.post<ApiResponse<number>>(memberAltAddressUrl, JSON.stringify(altAddress), options).pipe(
      map((response: ApiResponse<number>)=>{
        if (response.succeeded){
          console.log("MemberDemographics-saveMemberAltAddresses");
          return response.data;
        } else {
          return undefined;
        }
      })
    );
  }
  //END ALT ADDRESS METHODS
}
