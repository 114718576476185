import { EventEmitter, Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class EventService {
    public DueDateResetEvent = new EventEmitter();
    public TimeZoneEvent = new EventEmitter();
    public RequestTypeChangedEvent = new EventEmitter();
    public ProcedureAddedEvent = new EventEmitter();
    public AuthSavedEvent = new EventEmitter();

    public DueDateReset(){
        this.DueDateResetEvent.emit();
    }

    public TimeZone(){
        this.TimeZoneEvent.emit();
    }

    public RequestTypeChanged(){
        this.RequestTypeChangedEvent.emit();
    }

    ProcedureAdded(){
        this.ProcedureAddedEvent.emit();
    }

    AuthSaved(){
        this.AuthSavedEvent.emit();
    }
}