<!--
  List of templates in this file:
  1. #addTaskDialogTemplate: "Create New Task"
  2. #updateTaskDialogTemplate: "Update/Complete Task"
  3. #referTaskDialogTemplate: "Refer Tasks"
  4. #viewTaskDialogTemplate: "View Completed Task"

  Templates 1-3 above share the "Refer Tasks" content template below:
  - #referTaskContentTemplate: Refer Tasks Content
-->
<!-- 1. dialog content: Create New Task -->
<ng-template #addTaskDialogTemplate>
  <form [formGroup]="taskGroup">
    <div data-layout="row" data-layout-gap="2em">
      <div data-flex>
        <div class="text-lead mb-5">Auth#: {{ displayAuthNumber }}</div>
        <div data-layout="row" data-layout-align="space-between center" data-layout-gap="1em">
          <mat-form-field appearance="fill" style="width: 23em">
            <mat-label>Task type</mat-label>
            <mat-select required
                        formControlName="taskType"
                        (openedChange)="filterTaskType.value=''"
                        cdkFocusInitial>
              <input matInput placeholder="Search" #filterTaskType>
              <div *ngFor="let option of taskTypeValues">
                <mat-option [value]="option.lookupValueID" *ngIf="option.lookupStandardLabel.toLowerCase().includes((filterTaskType.value).toLowerCase())">
                  {{option.lookupStandardLabel}}
                </mat-option>
              </div>
            </mat-select>
            <mat-error *ngIf="taskType.touched && taskType.hasError('required')">Please select task type</mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill" style="width: 11em">
            <mat-label>Priority</mat-label>
            <mat-select formControlName="taskPriority"
                        (openedChange)="filterTaskPriority.value=''"
                        required>
              <input matInput placeholder="Search" #filterTaskPriority>
              <div *ngFor="let option of taskPriorityValues">
                <mat-option [value]="option.lookupValueID" *ngIf="option.lookupStandardLabel.toLowerCase().includes((filterTaskPriority.value).toLowerCase())">
                  {{option.lookupStandardLabel}}
                </mat-option>
              </div>
            </mat-select>
            <mat-error *ngIf="taskPriority.touched && taskPriority.hasError('required')">Please select task priority</mat-error>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Task Description</mat-label>
          <textarea matInput placeholder="" rows="6" formControlName="taskNarrative" required></textarea>
          <mat-error *ngIf="taskNarrative.touched && taskNarrative.hasError('required')">Please fill out task description</mat-error>
        </mat-form-field>

        <div data-layout="row" data-layout-gap="2em">
          <mat-form-field appearance="fill" style="width: 17em">
            <mat-label>Due Date/Time</mat-label>
            <input matInput type="datetime-local" name="dateTimeDueDate" formControlName="taskDueDate" required>
            <mat-error *ngIf="taskDueDate.touched && taskDueDate.hasError('required')">Please select task due date</mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" *ngIf="false" style="width: 17em">
            <mat-label>Due Date/Time</mat-label>
            <input matInput type="datetime-local" formControlName="taskCompletedDate" required>
          </mat-form-field>

          <mat-form-field appearance="fill" style="width: 17em">
            <mat-label>Reminder Date/Time</mat-label>
            <input matInput type="datetime-local" name="dateTimeReminderDate" formControlName="taskReminderDate">
          </mat-form-field>
        </div>

        <div data-layout="row" data-layout-gap="2em">
          <mat-form-field appearance="fill" style="width: 12em">
            <mat-label>Status</mat-label>
            <mat-select formControlName="taskStatus"
                        (selectionChange)="onStatusChange($event)"
                        (openedChange)="filterTaskStatus.value=''"
                        required>
              <input matInput placeholder="Search" #filterTaskStatus>
              <div *ngFor="let option of taskStatusValues">
                <mat-option [value]="option.lookupValueID" *ngIf="option.lookupStandardLabel.toLowerCase().includes((filterTaskStatus.value).toLowerCase())">
                  {{option.lookupStandardLabel}}
                </mat-option>
              </div>
            </mat-select>
            <mat-error *ngIf="taskStatus.touched && taskStatus.hasError('required')">Please select task status</mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" style="width: 22em">
            <mat-label>Outcome</mat-label>
            <mat-select formControlName="taskOutcome"
                        (openedChange)="filterTaskOutcome.value=''"
                        required>
              <input matInput placeholder="Search" #filterTaskOutcome>
              <div *ngFor="let option of taskOutcomeValuesDisplayed">
                <mat-option [value]="option.lookupValueID" *ngIf="option.lookupStandardLabel.toLowerCase().includes((filterTaskOutcome.value).toLowerCase())">
                  {{option.lookupStandardLabel}}
                </mat-option>
              </div>
            </mat-select>
            <mat-error *ngIf="taskOutcome.touched && taskOutcome.hasError('required')">Please select task outcome type</mat-error>
          </mat-form-field>
        </div>

        <div data-layout="row">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Outcome Description</mat-label>
            <textarea matInput placeholder="" rows="6" formControlName="taskOutcomeDescription" required></textarea>
            <mat-error *ngIf="taskOutcomeDescription.touched && taskOutcomeDescription.hasError('required')">Please fill out task outcome description</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div data-flex="33">
        <ng-template [ngTemplateOutlet]="referTaskContentTemplate">
        </ng-template>
      </div>
    </div>
  </form>
</ng-template>

<!-- 2. dialog content: Update/Complete Task -->
<ng-template #updateTaskDialogTemplate>
  <form [formGroup]="taskGroup">
    <div data-layout="row" data-layout-gap="2em">
      <div data-flex>
        <div class="text-lead mb-15">Auth#: {{task.authNumber}}</div>

        <div data-layout="row wrap" data-layout-align="space-between center" data-layout-gap="1em" class="mb-15">
          <div class="stacked-label stretch">
            <span class="label">Created</span>
            <span class="value">{{ task.createdDate | date: DateFormat}}</span>
          </div>
          <div class="stacked-label stretch">
            <span class="label">Created by</span>
            <span class="value">{{createdUserFirstLast}}</span>
          </div>
          <div class="stacked-label stretch">
            <span class="label">Assigned to</span>
            <span class="value">{{ownerUserFirstLast}}</span>
          </div>
        </div>

        <div data-layout="row" data-layout-align="space-between center" data-layout-gap="2em">
          <mat-form-field appearance="fill" style="width: 23em">
            <mat-label>Task type</mat-label>
            <mat-select required
                        formControlName="taskType"
                        [(value)]="task.typeId"
                        (openedChange)="filterTaskType.value=''"
                        cdkFocusInitial>
              <input matInput placeholder="Search" #filterTaskType>
              <div *ngFor="let option of taskTypeValues">
                <mat-option [value]="option.lookupValueID" *ngIf="option.lookupStandardLabel.toLowerCase().includes((filterTaskType.value).toLowerCase())">
                  {{option.lookupStandardLabel}}
                </mat-option>
              </div>
            </mat-select>
            <mat-error *ngIf="taskType.touched && taskType.hasError('required')">Please select task type</mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" style="width: 11em">
            <mat-label>Priority</mat-label>
            <mat-select formControlName="taskPriority"
                        (openedChange)="filterTaskPriority.value=''"
                        required>
              <input matInput placeholder="Search" #filterTaskPriority>
              <div *ngFor="let option of taskPriorityValues">
                <mat-option [value]="option.lookupValueID" *ngIf="option.lookupStandardLabel.toLowerCase().includes((filterTaskPriority.value).toLowerCase())">
                  {{option.lookupStandardLabel}}
                </mat-option>
              </div>
            </mat-select>
            <mat-error *ngIf="taskPriority.touched && taskPriority.hasError('required')">Please select task priority</mat-error>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Task Description</mat-label>
          <textarea matInput placeholder="" rows="6" formControlName="taskNarrative" required></textarea>
          <mat-error *ngIf="taskNarrative.touched && taskNarrative.hasError('required')">Please fill out task description</mat-error>
        </mat-form-field>

        <div data-layout="row" data-layout-gap="2em">
          <mat-form-field appearance="fill" style="width: 17em">
            <mat-label>Due Date/Time</mat-label>
            <input matInput type="datetime-local" name="dateTimeDueDate" formControlName="taskDueDate" required>
            <mat-error *ngIf="taskDueDate.touched && taskDueDate.hasError('required')">Please select task due date</mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" *ngIf="false" style="width: 17em">
            <mat-label>Due Date/Time</mat-label>
            <input matInput type="datetime-local" formControlName="taskCompletedDate" required value="{{ task.dueDate | date: DateFormat }}">
          </mat-form-field>

          <mat-form-field appearance="fill" style="width: 17em">
            <mat-label>Reminder Date/Time</mat-label>
            <input matInput type="datetime-local" name="dateTimeReminderDate" formControlName="taskReminderDate" required value="{{ task.dueDate | date: DateFormat }}">
          </mat-form-field>
        </div>

        <div data-layout="row" data-layout-gap="2em">
          <mat-form-field appearance="fill" style="width: 12em">
            <mat-label>Status</mat-label>
            <mat-select formControlName="taskStatus"
                        (selectionChange)="onStatusChange($event)"
                        (openedChange)="filterTaskStatus.value=''"
                        required>
              <input matInput placeholder="Search" #filterTaskStatus>
              <div *ngFor="let option of taskStatusValues">
                <mat-option [value]="option.lookupValueID" *ngIf="option.lookupStandardLabel.toLowerCase().includes((filterTaskStatus.value).toLowerCase())">
                  {{option.lookupStandardLabel}}
                </mat-option>
              </div>
            </mat-select>
            <mat-error *ngIf="taskStatus.touched && taskStatus.hasError('required')">Please select task status</mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" style="width: 22em">
            <mat-label>Outcome</mat-label>
            <mat-select formControlName="taskOutcome"
                        (openedChange)="filterTaskOutcome.value=''"
                        required>
              <input matInput placeholder="Search" #filterTaskOutcome>
              <div *ngFor="let option of taskOutcomeValuesDisplayed">
                <mat-option [value]="option.lookupValueID" *ngIf="option.lookupStandardLabel.toLowerCase().includes((filterTaskOutcome.value).toLowerCase())">
                  {{option.lookupStandardLabel}}
                </mat-option>
              </div>
            </mat-select>
            <mat-error *ngIf="taskOutcome.touched && taskOutcome.hasError('required')">Please select task outcome type</mat-error>
          </mat-form-field>
        </div>

        <div data-layout="row">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Outcome Description</mat-label>
            <textarea matInput placeholder="" rows="6" formControlName="taskOutcomeDescription" required></textarea>
            <mat-error *ngIf="taskOutcomeDescription.touched && taskOutcomeDescription.hasError('required')">Please fill out task outcome description</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div data-flex="33">
        <ng-template [ngTemplateOutlet]="referTaskContentTemplate">
        </ng-template>
      </div>
    </div>
  </form>
</ng-template>

<!-- 3. dialog content: Refer Task -->
<ng-template #referTaskDialogTemplate>
  <ng-template [ngTemplateOutlet]="referTaskContentTemplate">
  </ng-template>
</ng-template>

<ng-template #referTaskContentTemplate>
  <form [formGroup]="referGroup">
    <section class="section-container selectable" id="myAssignments" title="Select this action" [ngClass]="{'active': myAssignmentsSelected}" (click)="toggleActiveState($event)">
      <div data-layout="row" data-layout-align="space-between start">
        <h2 class="section-title mb-0">Add to My Assignments</h2>
        <mat-icon class="material-icons-outlined icon-dark isSelectedIcon">check</mat-icon>
      </div>
    </section>
    <section class="section-container selectable" id="workQueue" title="Select this action" [ngClass]="{'active': workQueueSelected}" (click)="toggleActiveState($event)">
      <div data-layout="row" data-layout-align="space-between start">
        <h2 class="section-title mb-5">Refer to Work Queue</h2>
        <mat-icon class="material-icons-outlined icon-dark isSelectedIcon">check</mat-icon>
      </div>
      <mat-form-field class="full-width no-hint white" appearance="outline">
        <mat-label>Select a work queue</mat-label>
        <mat-select (selectionChange)="OnQueueSelectionChange($event)"
                    formControlName="workQueueCtrl"
                    (openedChange)="filterWorkQueue.value=''">
          <input matInput placeholder="Search" #filterWorkQueue>
          <div *ngFor="let workQueueType of workQueueTypes">
            <mat-option [value]="workQueueType.queueId" *ngIf="workQueueType.queueName.toLowerCase().includes((filterWorkQueue.value).toLowerCase())">{{ workQueueType.queueName }}</mat-option>
          </div>
        </mat-select>
        <mat-error>Please select a work queue.</mat-error>
      </mat-form-field>
    </section>
    <section class="section-container selectable" id="teamMember" title="Select this action" [ngClass]="{'active': teamMemberSelected}" (click)="toggleActiveState($event)">
      <div data-layout="row" data-layout-align="space-between start">
        <h2 class="section-title mb-5">Refer to a team member</h2>
        <mat-icon class="material-icons-outlined icon-dark isSelectedIcon">check</mat-icon>
      </div>
      <mat-form-field class="full-width white" appearance="outline">
        <mat-label>Search Team Member</mat-label>
        <input type="text"
               matInput
               placeholder="Type name"
               [formControl]="teamMemberSearchCtrl"
               [matAutocomplete]="auto">
        <mat-icon matSuffix>search</mat-icon>
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="selectedTeamMember($event)">
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
            {{ option.firstName }} {{ option.lastName }}
          </mat-option>
        </mat-autocomplete>
        <mat-error>Please select a team member.</mat-error>
      </mat-form-field>
    </section>
  </form>
</ng-template>

<!-- 4. dialog content: View Completed Task -->
<ng-template #viewTaskDialogTemplate>
  <form>
    <h2 class="section-title mb-5">Type: {{task.taskType}}</h2>
    <div class="text-lead mb-15">Auth#: {{task.authNumber}}</div>
    <div data-layout="row wrap" data-layout-align="space-between center" data-layout-gap="1em" class="mb-15">
      <div data-flex="47.5%" class="stacked-label stretch">
        <span class="label">Created</span>
        <span class="value">{{task.createdDate | date: DateFormat}}</span>
      </div>
      <div data-flex="47.5%" class="stacked-label stretch">
        <span class="label">Completed</span>
        <span class="value">{{task.completedDate | date: DateFormat}}</span>
      </div>
    </div>
    <div data-layout="row wrap" data-layout-align="space-between center" data-layout-gap="1em" class="mb-30">
      <div data-flex="30%" class="stacked-label stretch">
        <span class="label">Outcome</span>
        <span class="value">{{task.outcomeDesc}}</span>
      </div>
      <div data-flex="30%" class="stacked-label stretch">
        <span class="label">Priority</span>
        <span class="value">{{task.priorityDescription}}</span>
      </div>
      <div data-flex="30%" class="stacked-label stretch">
        <span class="label">Task Status</span>
        <span class="value">{{task.statusDesc}}</span>
      </div>
    </div>
    <div data-layout="row" data-layout-align="start center" data-layout-gap="5em" class="mb-10">
      <span class="bold">Creation Notes</span>
      <span>Assigned To: {{ownerUserFirstLast}}</span>
    </div>
    <mat-form-field class="full-width mb-15 no-label" appearance="fill">
      <mat-label class="cdk-visually-hidden">Creation Notes</mat-label>
      <textarea matInput placeholder="" rows="6" disabled>{{task.narrative}}</textarea>
    </mat-form-field>
    <div data-layout="row" data-layout-align="start center" data-layout-gap="5em" class="mb-10">
      <span class="bold">Outcome Notes</span>
      <span>User: {{completedUserFirstLast}}</span>
    </div>
    <mat-form-field class="full-width no-label" appearance="fill">
      <mat-label class="cdk-visually-hidden">Outcome Notes</mat-label>
      <textarea matInput placeholder="" rows="6" disabled>{{task.outcomeNotes}}</textarea>
    </mat-form-field>
  </form>
</ng-template>
