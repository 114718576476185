import { Component, OnInit, ViewChild, TemplateRef, EventEmitter, AfterViewInit } from '@angular/core';
import { FormService } from '../../services/form-service.service';
import { AuthQueueData } from '../../shared/components/queue/AuthQueueData';
import { QueueService } from '../../services/queue.service';
import { queueTypes, ReferTaskTypes, TEAMQUEUEAUTHS } from '../../../assets/constants';
import { TokenStorageService } from '../../services/token-storage.service';
import { BehaviorSubject, Subscription, debounceTime, filter, map, pairwise, throttleTime } from 'rxjs';
import { ConfigService } from '../../services/config-service.service';
import { AutoUnsubscribe } from '../../shared/decorators/auto-unsubscribe.decorator';
import { BadgeData } from '../../models/filter/BadgeData';
import { FilterService } from '../../services/filter-service.service';
import { CdkVirtualScrollViewport, ScrollDispatcher } from '@angular/cdk/scrolling';
import { AppSettings } from '../../shared/appsettings';
import { EventService } from '@CommonLib/services/events.service';

@AutoUnsubscribe
export class AuthQueueUpdate {
  selectedAuths!: string;
  authOwner?:string | null;
  workQueueId?: number | null;
  currentUser!: string;
}

@Component({
  selector: 'app-team-queue-auths',
  templateUrl: './team-queue-auths.component.html',
  styleUrls: ['./team-queue-auths.component.scss']
})
export class TeamQueueAuthsComponent implements OnInit, AfterViewInit {
  @ViewChild('filterAuthDialogTemplate') filterAuthTemplate: TemplateRef<any> | undefined;
  @ViewChild(CdkVirtualScrollViewport) virtualScroll!: CdkVirtualScrollViewport;
  
  user: any;
  userGroupIds!: number[];
  procCodeIdList: any;
  procCodeDescList!: AuthQueueData[];
  filterBadgeData: BadgeData = {text: '', count: 0, filter_type: ''}
  openDialogEvent = new EventEmitter<boolean>();
  sub!: Subscription;
  healthPlanEmployeeAuths: boolean = false;
  searchPageNumber = 0;
  maxAuthCount = 0;
  maxQueueCount = 0;
  timezone = '';
  constructor(private formService: FormService, private queueService: QueueService, 
    private tokenService: TokenStorageService, private configService: ConfigService, private filterService: FilterService, private scrollDispatcher: ScrollDispatcher,
    private appSettings: AppSettings, private eventService: EventService) {
    this.user = this.tokenService.getUser();
    this.maxQueueCount = this.appSettings.maxQueueCount;
    this.timezone = this.appSettings.timeZone;
  }

  allAuthsListByGroup = new BehaviorSubject<AuthQueueData[]>([]);
  authListByGroup: AuthQueueData[]=[];
  selectedAuths: AuthQueueData[] = [];
  queueDisplayCount: number = 0;
  gotUserGroups = false;
  filterType = TEAMQUEUEAUTHS;
  unfilteredAuthListByGroup: AuthQueueData[]=[];
  countMessage = '';

  //Refresh auth queue icon 
  refreshTitleText = 'Refresh auth queue';
  refreshQueue() {
    this.searchPageNumber=0;
    this.formService.getAuthIntakeWorkQueueByGroup(this.userGroupIds).subscribe();
    this.authListByGroup.map(f => { f.hidden = false; });
    this.queueDisplayCount = this.authListByGroup.filter(a => !a.hidden).length;
    this.countMessage = `${this.queueDisplayCount.toLocaleString()} of ${this.unfilteredAuthListByGroup.length.toLocaleString()}`
    this.authListByGroup = [...this.authListByGroup];
    this.allAuthsListByGroup.next(this.authListByGroup);
  }

  //Select or deselect all checkboxes
  checked: boolean = false;
  selectOrDeselectAll() {
    let value = this.checked ? true : false;
    this.authListByGroup.filter(a => a.hidden === false).forEach(element => {
      element.checked = value;
    });
    this.allAuthsListByGroup.next(this.authListByGroup);
    this.updateSelectedAuths();
  }

  ngAfterViewInit() {
    this.virtualScroll.elementScrolled().pipe(
      map(() => this.virtualScroll.measureScrollOffset('bottom')),
      pairwise(),
      filter(([y1, y2]) => (y2 < y1 && y2 < 140)),
      throttleTime(200)
    ).subscribe(() => {
      this.searchPageNumber++;
        let pagedData = this.formService.getPagedAuthWorkQueueByGroup(this.searchPageNumber*this.maxQueueCount,this.maxQueueCount);
        if (pagedData){
          let auths = this.authListByGroup.concat(pagedData);
          this.populateAuthList(auths);
        }
    })
  }

  openFilterDialog() {
    this.openDialogEvent.emit(true);
  }

  referDialogEvent(event: any) {
    if (event.referType == ReferTaskTypes.MY_ASSIGNMENT) {
      this.selectedAuths.forEach(t => {
        const index = this.authListByGroup.findIndex(f => f.authNumber == t.authNumber);
        this.authListByGroup.splice(index,1);
        this.allAuthsListByGroup.next(this.authListByGroup);
        if (t.healthPlanEmployeeFlag){
          this.healthPlanEmployeeAuths = true;
        }
      });
      this.queueDisplayCount = this.authListByGroup.filter(a => a.hidden === false).length;
      this.countMessage = `${this.queueDisplayCount.toLocaleString()} of ${this.unfilteredAuthListByGroup.length.toLocaleString()}`
      this.queueService.transferItems(queueTypes.MEMBER_AUTHORIZATION_QUEUE, this.selectedAuths);
    }

    let authDetailIDList = '';
    this.selectedAuths.forEach(s => {
      if (authDetailIDList.length > 0)
      authDetailIDList = authDetailIDList + ',';
      authDetailIDList = authDetailIDList + s.authDetailId;
      if (s.healthPlanEmployeeFlag){
        this.healthPlanEmployeeAuths = true;
      }
    });
    let updateParams = new AuthQueueUpdate();
    const user = this.user.profile.preferred_username;
    switch (event.referType) {
      case ReferTaskTypes.MY_ASSIGNMENT:
        updateParams = {currentUser: user, authOwner: user, workQueueId: null, selectedAuths: authDetailIDList}
        break;
      case ReferTaskTypes.TEAM_MEMBER:
        updateParams = {currentUser: user, authOwner: event.teamMemberId, workQueueId: null, selectedAuths: authDetailIDList}
        break;
      case ReferTaskTypes.WORK_QUEUE:
        updateParams = {currentUser: user, authOwner: null, workQueueId:  event.workQueueTypeId, selectedAuths: authDetailIDList}
        break;
    }
    this.formService.updateAuthQueues(updateParams);
    this.resetSelectedAuths(this.selectedAuths);
  }

  ngOnInit(): void {
    var availableQueues: number[] = [];
    this.healthPlanEmployeeAuths = false;
    this.maxAuthCount = 109;
    this.sub = this.formService.AuthWorkQueueList.subscribe(data => {
      if (data.length > 0) {
        this.unfilteredAuthListByGroup = data;
        let pagedData = this.formService.getPagedAuthWorkQueueByGroup(0,this.maxQueueCount);
        if (pagedData){
          this.populateAuthList(pagedData);
        }
      }
    });

    this.sub.add(this.configService.userGroupList.subscribe(data => {
        this.userGroupIds = data.map(d => d.queueId);
    }));

    this.sub.add(this.formService.QueueUpdated.subscribe(data => {
      if (data) {
        this.formService.getUmDashboardCounts();
      }
    }));

    this.sub.add(this.filterService.badgeDataBs.subscribe(bd => {
      if (bd.filter_type == TEAMQUEUEAUTHS) {
        this.filterBadgeData = bd;
      }
    }));
    this.searchPageNumber = 0;

    this.sub.add(this.eventService.TimeZoneEvent.subscribe(t => {
      this.timezone = this.appSettings.timeZone;
    }))
  }

  populateAuthList(data: AuthQueueData[]){
    this.authListByGroup = data;

    if (this.selectedAuths != null && this.selectedAuths != undefined && this.selectedAuths.length > 0) {
      this.authListByGroup.forEach(element => {
        let index = this.selectedAuths.findIndex(f => f.authNumber == element.authNumber);
        if (index >= 0) {
          element.checked = true;
        }
        else
          element.checked = false;
      });
    }

    this.authListByGroup = this.filterService.applySessionFilter(this.authListByGroup, TEAMQUEUEAUTHS);
    this.authListByGroup = [...this.authListByGroup.filter(a => !a.hidden)];
    this.queueDisplayCount = this.authListByGroup.filter(a => !a.hidden).length;
    this.countMessage = `${this.queueDisplayCount.toLocaleString()} of ${this.unfilteredAuthListByGroup.length.toLocaleString()}`
    //Store the proc code desc for tooltip
    this.procCodeDescList = JSON.parse(JSON.stringify(this.authListByGroup.filter(
      (auth, i, arr) => arr.findIndex(t => t.procCodeId === auth.procCodeId) === i
    ))) as typeof this.authListByGroup;
    this.allAuthsListByGroup.next(this.authListByGroup);
  }

  updateSelectedAuths(): void {
    this.selectedAuths.splice(0, this.selectedAuths.length)
    this.authListByGroup.forEach(element => {
      if (element.checked) {
        this.selectedAuths.push(element);
      }
    });
  }

  authSelectedToggle(e: any) {
    let index = -1;
    if (e.authNumber != undefined) {
      index = this.selectedAuths.findIndex(t => t.authNumber == e.authNumber);
    }
    if (e.checked && e.authNumber != undefined && this.authListByGroup.findIndex(t => t.authNumber == e.authNumber) != undefined) {
      this.selectedAuths.push(this.authListByGroup.find(t => t.authNumber == e.authNumber)!);
    }
    else {
      this.selectedAuths.splice(index,1);
    }
    this.resetHealthEmployeeFlag();
  }

  resetHealthEmployeeFlag(){
    this.healthPlanEmployeeAuths = false;
    this.selectedAuths.forEach(s => {
      if (s.healthPlanEmployeeFlag){
        this.healthPlanEmployeeAuths = true;
      }
    })
  }

  resetSelectedAuths(list: AuthQueueData[]): void {
    list.map(t => t.authDetailId).map(t => {
      const auth = this.authListByGroup.find(tt => tt.authDetailId === t);
      if (auth != undefined)  auth.checked = false;
    });
    this.allAuthsListByGroup.next(this.authListByGroup);
    this.selectedAuths = [];
  }

  dialogEvent() {
    this.queueDisplayCount = this.authListByGroup.filter(a => !a.hidden).length;
    this.countMessage = `${this.queueDisplayCount.toLocaleString()} of ${this.unfilteredAuthListByGroup.length.toLocaleString()}`
  }

  displayCountUpdate(count: number) {
    this.queueDisplayCount = count;
    this.countMessage = `${this.queueDisplayCount.toLocaleString()} of ${this.unfilteredAuthListByGroup.length.toLocaleString()}`
    this.authListByGroup = [...this.unfilteredAuthListByGroup.filter(a => !a.hidden)];
    this.allAuthsListByGroup.next(this.authListByGroup);
  }
}


