import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class TooltipService {
  constructor() {
  }

  getErrorText(key: string, keyError: string, customError: string|null): string {
    switch (key) {
      case 'authTypeId':
        return 'Auth Type must be selected';
      case 'treatmentTypeId':
        return 'Treatment Type must be selected';
      case 'requestTypeId':
        return 'Request Type must be selected';
      case 'urgencyId':
        return 'Level of Urgency must be selected';
      case 'placeOfServiceId':
        return 'Place of Service must be selected';
      case 'requestOriginId':
        return 'Origin of Request must be selected';
      case 'authRequestDate':
        return 'Auth Reqested Date needs to be selected';
      case 'authRequestTimeCtrl':
        return 'Auth Reqested Time needs to be selected';
      case 'requestorType':
        return 'Request Type must be selected';
      case 'firstName':
        return 'Requestor First Name is required';
      case 'lastName':
        return 'Requestor Last Name is required';
      case 'emailFormControl':
        return 'Requestor Email is required.';
      case 'dischargeDate':
        return 'Discharge date is required.';
      case 'dischargeDisposition':
        return 'Discharge disposition is required.';
      case 'summary':
        return 'Disposition summary is required.';
      case 'admissionTypeId':
        return 'Admission Type is required.';
      case 'admitDate':
        return 'Admit date is required.';
      case 'requestedLos':
        return 'Requested LOS is required.';
      case 'expectedDischargeDate':
        return 'Expected discharge date is required.';
      case 'ProcUnitRequested':
        return 'Procedure UnitsRequested required.';      
      case 'ProcedureUnit':
        return 'Procedure UnitType required.';
      case 'ProcedureStart':
        return 'Procedure Start date required.';
      case 'ProcedureEnd':
        return 'Procedure End date required.';
      case 'rxStart':
        return 'Prescription Start date is required.';
      case 'rxEnd':
        return 'Prescription End date is required.';
      case 'rxQty':
        if (keyError == 'max'){
          return 'Prescription Quantity must be 1-5 digits only.'
        } else if (keyError == 'pattern'){
          return 'Prescription Quantity must a numeric value.'
        }
        return 'Prescription Quantity is required.';
      case 'rxFrequencyFA':
          return 'Prescription Frequency is required.';
      case 'rxFrequencyCustom':
          return 'Custom Prescription Frequency is required.';
      case 'rxRouteFA':
        return 'Prescription Route is required.';
      case 'eligibility':
        return 'Please select a valid Eligibility.';
      case 'requestOriginOther':
        return 'Enter other request origin.';
      case 'phoneNumber':
        return 'Phone Number is required.';
      case 'aORDate':
        return 'AOR Recieved Date is required.';
      case 'dischargeDispostion':
        return 'Expected Discharge Disposition is required.'
      case 'status':
        return `Status must be selected.`;
      case 'statusReason':
        return 'Status Reason must be selected.'
      case 'rationale':
        return 'Rationale must be entered.'
      case 'reviewOutcomeReason':
        return 'Outcome Reason must be selected.'
      case 'reviewRationale':
        return 'Rationale must be entered.'
      case 'approvedStartDate':
        if (keyError && keyError == 'validityError'){
          return customError??'';
        }
        return 'Approved start date must be selected.'
      case 'approvedEndDate':
        if (keyError && keyError == 'validityError'){
          return customError??'';
        }
        return 'Approved end date must be selected.'
      default:
        return key + ': ' + keyError;
    }
  }
}
