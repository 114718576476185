import { Component, ElementRef, Inject, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { additionalID, newReview, patient } from '../../../models/interqual/newReview';
import { MemberDemographic } from '@CommonLib/models/member/memberDemographic';
import { FormService } from '../../../services/form-service.service';
import { InterqualService } from '../../../services/interqual.service';
import { MemberService } from '../../../services/member-service.service';
import { TokenStorageService } from '../../../services/token-storage.service';
import { DialogComponent } from '@CommonLib/components/dialog/dialog.component';
import { DatePipe } from '@angular/common';
import { LookupService } from '../../../services/lookup.service';
import { LookupValue } from '../../../models/Lookups/lookupValue';
import { AppSettings } from '../../appsettings';
import { Provider } from '../../../models/Lookups/provider';
import { ConfigService } from '../../../services/config-service.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthIntakeDetail } from '../../../models/authForm/authIntakeDetail';
import { AuthIntakeForm } from '../../../models/authForm/authIntakeForm';
import { AuthIntakeReviewDetail } from '../../../models/authForm/authIntakeReviewDetail';
import { DATE_MDY, PROVIDER_TYPE } from 'projects/um/src/assets/constants';
import { AuthIntakeAttachment } from '../../../models/authForm/authIntakeAttachment';
import { BlobResponseDto } from '../../../models/storage/blobResponseDto';
import { DocumentService } from '@CommonLib/services/docStorage.service';

declare const InterQualOnline: any;

@Component({
  selector: 'interqual',
  templateUrl: './interqual.component.html',
  styleUrls: ['./interqual.component.scss']
})
export class InterqualComponent implements OnInit, OnDestroy {
  IQIframeScript: HTMLScriptElement;
  authForm!: AuthIntakeForm;
  formsSub!: Subscription;
  userName!: string;
  demographics!: MemberDemographic;
  oneTimeToken: string = '';
  safeUrl: SafeResourceUrl = '';
  guidelineRestrictedForFacility: boolean = false;
  appReadyReceived: boolean = false;
  //Review Saved Values
  reviewSaveId!: string;
  contentType!: string;
  reviewXml!: string;
  //End Review Saved
  //Review Specific values
  productDesc!: string;
  subsetDesc!: string;
  versionDesc!: string;
  currentStatusDesc!: string;
  criteriaMet!: string;
  recLos: number = 0;
  //End Review Specific values
  externalId!: string;
  lookupData!: LookupValue[];
  reviewType!: number;
  createdDate!: string;
  createdBy!: string;
  updatedDate!: string;
  updatedBy!: string;
  interqualSendPhi!: boolean;
  currentProvider!: Provider;
  bookView: boolean = false;
  interqualFacilityID!: string;
  enableExternalGuideline!: boolean;
  DateFormat = DATE_MDY;

  @ViewChild('iframe', { static: false }) iframe!: ElementRef;
  constructor(private tokenService: TokenStorageService, private formService: FormService, private interqualService: InterqualService,
    private memberService: MemberService, private sanitizer: DomSanitizer, private dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private lookupService: LookupService, private appSettings: AppSettings, private configService: ConfigService,
    private storageService: DocumentService ) {
    //load interQual script
    this.IQIframeScript = document.createElement('script');
    this.IQIframeScript.src = '../../../../../assets/js/interqual-api-1.8.js';
    document.body.appendChild(this.IQIframeScript);
    (<any>window).createNewRequest = this.createNewRequest.bind(this);
    (<any>window).saveReview = this.saveReview.bind(this); 
    (<any>window).reviewPdf = this.handleReviewSummaryPdf.bind(this);
    (<any>window).handleNewReviewStarted = this.handleNewReviewStarted.bind(this);
    (<any>window).externalId = data.externalId;
    (<any>window).reviewType = data.reviewType;
    (<any>window).startReviewWithoutPHI = this.startReviewWithoutPHI.bind(this);
    (<any>window).startReviewWithPHI = this.startReviewWithPHI.bind(this);
    (<any>window).bookView = data.bookView;
    (<any>window).startBookReview = this.startBookReview.bind(this);
    (<any>window).saveReviewPdf = this.saveReviewPdf.bind(this);
    (<any>window).handleReviewSaved = this.handleReviewSaved.bind(this);
    if (this.appSettings.features && this.appSettings.features['externalGuidelines']) {
      this.enableExternalGuideline = JSON.parse(this.appSettings.features['externalGuidelines']);
    }
  }

  ngOnInit(): void {
    this.userName = this.tokenService.getUser().profile.preferred_username;
    if (this.enableExternalGuideline) {
      this.interqualService.loginInterqual();
      //get AuthIntake Form object
      this.formsSub = this.formService.AuthForm.subscribe(data => {
        if (data && data.authIntakeHeader && data.authIntakeHeader.authIntakeId > 0) {
          this.authForm = data;
          this.memberService.getMemberDemographics(this.authForm.authIntakeHeader.mvdId, this.authForm.authIntakeDetail[0].authDetailId).subscribe();
          (<any>window).parent.authForm = data;
        }
      });

      this.formsSub.add(this.memberService.MemberDemographics.subscribe(data => {
        if (data) {
          this.demographics = data;
        }
      }))

      this.formsSub.add(this.lookupService.LookupData.subscribe(l => {
        if (l) {
          this.lookupData = l;
        }
      }))

      this.formsSub.add(this.interqualService.LoginUrl.subscribe(data => {
        if (data) {
          this.oneTimeToken = data;
          this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data);
        } else {
          this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data);
        }
      }));
      if (this.authForm && this.authForm.authIntakeHeader.srvcFinalProviderHashId) {
        this.formsSub.add(this.lookupService.getProviderByHashId(this.authForm.authIntakeHeader.srvcFinalProviderHashId, this.authForm.authIntakeDetail[0].authDetailId, PROVIDER_TYPE.SERVICING).subscribe((res) => {
          if (res.succeeded && res.data) {
            this.currentProvider = res.data;
          }
        }));
      }

      this.configService.interqualSettings.subscribe(s => {
        if (s) {
          (<any>window).interqualSendPhi = s.sendPHI;
          (<any>window).interqualSendServiceData = s.sendServiceInfo;
          (<any>window).interqualFacilityID = s.samlFacilityId;
        }
      })

      this.formsSub.add(this.storageService.interqualAttachDocumentId.subscribe(d => {
        if (d && d.blob) {
          this.updateAttachment(d);
        }
      }))
    }
  }

  ngOnDestroy() {
    this.formsSub?.unsubscribe();
  }

  onClose() {
    this.dialogRef.close();
  }

  init() {
    InterQualOnline.initialize(
      {
        interqualFrameElementId: 'iqconnectFrame',
        errorHandler: this.genericErrorHandler,
        newReviewStartedHandler: this.handleNewReviewStarted,
        reviewSavedHandler: this.handleReviewSaved,
        reviewOpenedHandler: this.handleReviewOpened,
        reviewSummaryPdfHandler: this.handleReviewSummaryPdf,
        reviewSharedHandler: this.handleReviewShared,
        reviewUnsharedHandler: this.handleReviewUnshared,
        reviewStateChangedHandler: this.handleReviewStateChanged,
        initializedHandler: this.handleInitialized,
        applicationReadyHandler: this.handleApplicationReady,
        applicationLogoutHandler: this.handleApplicationLogout,
        guidelineRestricted: false,
        selectedFacilityId: (<any>window).parent.interqualFacilityID,
        customSettings: '{"hideMenuButton":false, "hideSaveReviewButton": false,"hideRecommendationsPreviousButton": false}'
      })
  }

  genericErrorHandler(error: any) {
    console.log(error);
  }

  handleNewReviewStarted(result: any) {
    this.handleNewReviewStartedSilently(result);
    //showStatus('SUCCESS');
    var message = 'New Review Started';
    if (result) {
      if (result.guidelineId || result.productId) {
        message += (result.guidelineId ? ' for subset ' +
          result.guidelineId : ' for product ' + result.productId
        );
        if (result.cptCodes && result.cptCodes.length > 0) {
          message += ' cptCodes ' + result.cptCodes;
        }
        if (result.hcpcsCodes && result.hcpcsCodes.length > 0) {
          message += ' hcpcsCodes ' + result.hcpcsCodes;
        }
      }
      if (result.patient) {
        message += ' for patient' + (result.message ? ': ' +
          result.message : '');
      }
      else {
        message += ' no patient info provided' +
          (result.message ? ': ' + result.message : '');
      }
    }
  }

  handleNewReviewStartedSilently(result: any) {
    //document.getElementById('reviewSaveId').value = '';
    //document.getElementById('reviewXml').value = '';
    //document.getElementById('contentType').value = '';
  }

  handleReviewSaved(result: any) {
    const parser = new DOMParser();
    const xmlResult = parser.parseFromString(result.review_xml, "text/xml");

    (<any>window).parent.reviewSaveId = result.UUID;
    (<any>window).parent.contentType = result.content_type;
    (<any>window).parent.reviewXml = result.review_xml;
    (<any>window).parent.productDesc = xmlResult.getElementsByTagName("Criteria")[0].getAttribute('ProductDesc');
    (<any>window).parent.subsetDesc = xmlResult.getElementsByTagName("Criteria")[0].getAttribute("SubsetDesc");
    (<any>window).parent.versionDesc = xmlResult.getElementsByTagName("Criteria")[0].getAttribute("VersionDesc");
    (<any>window).parent.currentStatusDesc = xmlResult.getElementsByTagName("Review")[0].getAttribute("CurrentStatusDesc");
    (<any>window).parent.criteriaMet = xmlResult.getElementsByTagName("Criteria")[0].getAttribute("CriteriaMet");
    let benchmarklos = xmlResult.getElementsByTagName("BenchmarkLOS");
    if (benchmarklos && benchmarklos.length > 0) {
      for (let i = 0; i < benchmarklos[0].children.length; i++) {
        if (benchmarklos[0].children[i].getAttribute("Selected")) {
          (<any>window).parent.recLos = benchmarklos[0].children[i].getAttribute("Days");
          break;
        }
      }
    }
    (<any>window).parent.createdDate = xmlResult.getElementsByTagName("Review")[0].getAttribute("CreatedDateTime");
    (<any>window).parent.createdBy = xmlResult.getElementsByTagName("Review")[0].getAttribute("CreatedByUserDesc");
    (<any>window).parent.updatedDate = xmlResult.getElementsByTagName("Review")[0].getAttribute("LastChangedDateTime");
    (<any>window).parent.updatedBy = xmlResult.getElementsByTagName("Review")[0].getAttribute("LastChangedUserDesc");

    let currentStatus = xmlResult.getElementsByTagName("Review")[0].getAttribute("CurrentStatusDesc");
    if (currentStatus && currentStatus.toLowerCase() == 'completed'){
      InterQualOnline.reviewSummaryPdf({
        savedReviewId: result.UUID, includeNotes: 'true'
        }, (<any>window).parent.handleReviewSummaryPdf, function (errorMessage: any) { 
          console.log('FAILURE - Could not retrieve PDF ' + errorMessage); 
        });
    } else {
      (<any>window).parent.saveReview();
      (<any>window).parent.recLos = 0;
    }
  }

  saveReview() {
    let guidelineType = this.lookupData.find(l => l.lookupStandardValue.toLowerCase() == 'external' && l.lookupSetID == 132);
    let reviewDetail = this.authForm.authIntakeReviewDetail.find(d => d.guidelineUrl == (<any>window).parent.reviewSaveId && d.guidelineTypeId == (guidelineType ? guidelineType.lookupValueID : 10165));
    if (!reviewDetail) {
      reviewDetail = new AuthIntakeReviewDetail();
      this.authForm.authIntakeReviewDetail.push(reviewDetail);
    }
    reviewDetail.authDetailID = this.authForm.authIntakeDetail[0].authDetailId;
    reviewDetail.authReviewId = 0;
    reviewDetail.reviewTypeId = (<any>window).parent.reviewType; 
    reviewDetail.guidelineTypeId = guidelineType ? guidelineType.lookupValueID : 10165; //DEFAULT to External
    reviewDetail.guidelineName = (<any>window).parent.productDesc + ' - ' + (<any>window).parent.subsetDesc;
    reviewDetail.guidelineUrl = (<any>window).parent.reviewSaveId;
    reviewDetail.guidelineResults = (<any>window).parent.criteriaMet;
    reviewDetail.guidelineRecUnits = +(<any>window).parent.recLos;
    if (!reviewDetail.createdBy) {
      reviewDetail.createdBy = this.userName;
      reviewDetail.createdDate = new Date().toISOString();
    }
    reviewDetail.updatedBy = this.userName;
    reviewDetail.updatedDate = new Date().toISOString();

    if (!this.authForm.authIntakeInterqualReview){
      this.authForm.authIntakeInterqualReview = [];
    }

    if (this.authForm.authIntakeInterqualReview) {
      let existingRev = this.authForm.authIntakeInterqualReview.find(i => i.externalReviewId == (<any>window).parent.reviewSaveId);
      if (existingRev) {
        existingRev.externalReviewXml = (<any>window).parent.reviewXml;
        existingRev.externalReviewStatus = (<any>window).parent.currentStatusDesc;
        existingRev.updatedBy = (<any>window).parent.updatedBy;
        existingRev.updatedDate = new Date((<any>window).parent.updatedDate).toISOString();
      } else {
        existingRev = {
          authReviewDetailID: reviewDetail.authReviewDetailId,
          externalReviewId: (<any>window).parent.reviewSaveId,
          externalReviewXml: (<any>window).parent.reviewXml,
          externalReviewStatus: (<any>window).parent.currentStatusDesc,
          createdBy: (<any>window).parent.createdBy,
          createdDate: new Date((<any>window).parent.createdDate).toISOString(),
          updatedBy: (<any>window).parent.updatedBy,
          updatedDate: new Date((<any>window).parent.updatedDate).toISOString(),
          obsolete: false,
          copiedValue: false
        }
        this.authForm.authIntakeInterqualReview.push(existingRev);
      }
    } 
    this.formService.saveAuthForm();
  }

  handleReviewOpened(result: any) {
    console.log('Review Opened Successfully: ' + result.UUID);
  }

  handleReviewSummaryPdf(result: any) {
    (<any>window).parent.externalPdf = new Blob([result], {type: 'application/pdf'});
    
    (<any>window).parent.saveReviewPdf();
  }

  saveReviewPdf(){
    var id = this.lookupService.getLookupValueId('externalreview',121);
    if (!id){
      id = 10401;//Hard coded value if there is an issue getting the type.
    }

    let attachment = new AuthIntakeAttachment();
    attachment.attachmentName = (<any>window).parent.productDesc + ' - ' + (<any>window).parent.subsetDesc;
    attachment.attachmentTypeId = id;
    attachment.obsolete = false;
    
    this.authForm.authIntakeAttachments.push(attachment);
    var formData = new FormData();
    formData.append('file', (<any>window).parent.externalPdf);
    formData.append('fileName', attachment.attachmentName);
    this.storageService.uploadDocument(formData, attachment.attachmentName, attachment.attachmentName, '', 'externalreview', true, 
        this.authForm.authIntakeDetail[0].authDetailId,'um_attachment',this.appSettings.storageServiceBaseUrl,this.appSettings.customerId,
        this.appSettings.productId,this.userName,'um', true).subscribe();
        //(<any>window).parent.saveReview();
        //(<any>window).parent.recLos = 0;
  }

  updateAttachment(blob: BlobResponseDto) {
    let guid: string | undefined = '';
    var attach = this.authForm.authIntakeAttachments.find(a => a.attachmentName == blob.blob.displayName && (a.authDetailId == undefined || a.authDetailId <= 0));
    if (attach) {
      if (blob.blob.name) {
        guid = blob.blob.name.split('/').pop();
        if (guid) {
          attach.attachmentStorageId = guid;
        }
      }
      if (blob.blob.uri) {
        attach.attachmentUrl = blob.blob.uri;
      }
      if (this.authForm.authIntakeAttachments.filter(a => !a.attachmentUrl).length <= 0) {
        (<any>window).parent.saveReview();
        (<any>window).parent.recLos = 0;
        //this.formService.saveAuthForm();
        this.storageService.resetStorageDocumentId();
      }
    }
  }
  
  handleReviewShared() {

  }

  handleReviewUnshared() {

  }

  handleReviewStateChanged() {

  }

  handleInitialized(data: any) {
    if (data) {
      console.log(data.apiVersion);
    }
  }

  handleApplicationReady(data: any) {
    if (data) {
      console.log('APP: ' + data.apiVersion + '/' + data.appVersion);
      (<any>window).parent.appReadyReceived = true;
    }
    let id = (<any>window).parent.externalId;
    if ((<any>window).parent.bookView) {
      (<any>window).parent.startBookReview();
    }
    else if (!id) {
      if ((<any>window).parent.interqualSendPhi) {
        (<any>window).parent.startReviewWithPHI();
      } else {
        (<any>window).parent.startReviewWithoutPHI();
      }
    } else {
      InterQualOnline.openReview({
        savedReviewId: id
      }, function (result:any) {
        console.log('SUCCESS – Review Opened');
      }, function (errorMessage:any) {
        console.log('FAILURE - Could not open review: ' + errorMessage);
        (<any>window).parent.alertService.error(errorMessage);
      });
    }
  }

  startReviewWithPHI() {
    let newRev = (<any>window).parent.createNewRequest();
    const inpatient = (<any>window).parent.authForm.authIntakeDetail.find((d: AuthIntakeDetail) => d.requestedLos && d.requestedLos > 0);
    //Inpatient => send ONLY Diagnosis
    if (inpatient) {
      InterQualOnline.newReview({
        medicalCode: newRev.medicalCode,
        serviceState: (<any>window).parent.interqualSendServiceData ? newRev.serviceState : '',
        serviceDate: (<any>window).parent.interqualSendServiceData ? newRev.serviceDate : '',
        patient: {
          firstName: newRev.patient.firstName,
          lastName: newRev.patient.lastName,
          dob: newRev.patient.dob,
          gender: newRev.patient.gender,
          externalID: newRev.patient.externalID,
            additionalIDs: [{
              type: newRev.patient.additionalIDs[0].type,
              id: newRev.patient.additionalIDs[0].id}]}
      }, (<any>window).parent.handleNewReviewStarted);
    } else { //Outpatient => send Procedure Codes/HCPCS
      InterQualOnline.newReview({
        medicalCode: newRev.cptCodes,
        serviceState: (<any>window).parent.interqualSendServiceData ? newRev.serviceState : '',
        serviceDate: (<any>window).parent.interqualSendServiceData ? newRev.serviceDate : '',
        patient: {
          firstName: newRev.patient.firstName,
          lastName: newRev.patient.lastName,
          dob: newRev.patient.dob,
          gender: newRev.patient.gender,
          externalID: newRev.patient.externalID,
          additionalIDs: [{
            type: newRev.patient.additionalIDs[0].type,
            id: newRev.patient.additionalIDs[0].id
          }]
        }
      }, (<any>window).parent.handleNewReviewStarted);
    }
  }

  startReviewWithoutPHI() {
    let newRev = (<any>window).parent.createNewRequest();
    const inpatient = (<any>window).parent.authForm.authIntakeDetail.find((d: AuthIntakeDetail) => d.requestedLos && d.requestedLos > 0);
    //Inpatient => send ONLY Diagnosis
    if (inpatient) {
      InterQualOnline.newReview({
        medicalCode: newRev.medicalCode
      }, (<any>window).parent.handleNewReviewStarted);
    } else { //Outpatient => send Procedure Codes/HCPCS
      InterQualOnline.newReview({
        medicalCode: newRev.cptCodes
      }, (<any>window).parent.handleNewReviewStarted);
    }
  }

  startBookReview() {
      InterQualOnline.newReview({
        bookview: true
      }, (<any>window).parent.handleNewReviewStarted);
  }

  handleApplicationLogout() {

  }

  createNewRequest(): newReview {
    const datepipe: DatePipe = new DatePipe('en-US');
    let serviceDate = '';
    const addIDs: additionalID[] = [{
      type: 'Authorization',
      id: this.authForm.authIntakeHeader.displayAuthNumber
    }];

    const pat: patient = {
      firstName: this.demographics.firstName,
      lastName: this.demographics.lastName,
      dob: datepipe.transform(this.demographics.dob, this.DateFormat),
      gender: this.demographics.gender=='M'?'MALE':'FEMALE',
      externalID: this.authForm.authIntakeHeader.mvdId,
      externalIDType: 'MVDID',
      additionalIDs: addIDs
    };
    var diagnosis = '';
    var procs: string[] = [];
    var hcpcs: string[] = [];

    if (this.authForm.authIntakeDxes && this.authForm.authIntakeDxes.length > 0) {
      let primary = this.authForm.authIntakeDxes.filter(d => d.isPrimary == true);
      diagnosis = Array.prototype.map.call(primary, s => s.dxCodeId).toString();
    }
    if (this.authForm.authIntakeProcCodes && this.authForm.authIntakeProcCodes.length > 0) {
      let proc = this.authForm.authIntakeProcCodes.filter(p => p.procType.toLowerCase() == 'p');
      if (proc && proc.length > 0) {
        procs = proc.map(s => s.procCodeId);
      }
      var sDate = this.authForm.authIntakeProcCodes.reduce((min, p) => (p.fromDate ?? '') < (min ?? '') ? p.fromDate : min, this.authForm.authIntakeProcCodes[0].fromDate);
      if (sDate) {
        serviceDate = sDate;
      }
    }
    
    let newRev = {
      medicalCode: diagnosis,
      cptCodes: procs,
      hcpcsCodes: hcpcs,
      serviceState: this.currentProvider?.serviceState,
      serviceDate: datepipe.transform(serviceDate, this.DateFormat),
      patient: pat
    }
    return newRev;
  }

  updateStatus(){
    var uuid = (<any>window).externalId;
    InterQualOnline.openReview({
      savedReviewId: uuid,
      dataOnly: true,
      dataFormat: 'XML'
    }, function (result:any) {
      console.log('SUCCESS – Review Opened');
      (<any>window).parent.handleReviewSaved(result);
    }, function (errorMessage:any) {
      console.log('FAILURE - Could not open review: ' + errorMessage);
      (<any>window).parent.alertService.error(errorMessage);
    });
  }
}
